<script lang="ts">
  import { onMount } from 'svelte'
  import Number from './Number.svelte'
  import Button from './Button.svelte'
  let time = new Date()
  let hour = 0
  let min = 0
  let totalBreak = 40

  function setCurrentTime() {
    const d = new Date()
    hour = d.getHours()
    min = d.getMinutes()
  }
  function padLeft(numb) {
    if (isNaN(numb)) {
      return '00'
    }
    return ('00' + numb).slice(-2)
  }
  $: breakOverHour = padLeft(
    (parseInt(hour) + Math.floor((parseInt(totalBreak) + parseInt(min)) / 60)) %
      24
  )
  $: breakOverMin = padLeft((parseInt(totalBreak) + parseInt(min)) % 60)
  $: breakTime = new Date()

  $: {
    breakTime.setHours(
      (parseInt(hour) +
        Math.floor((parseInt(totalBreak) + parseInt(min)) / 60)) %
        24
    )
    breakTime.setMinutes((parseInt(totalBreak) + parseInt(min)) % 60)
  }

  onMount(() => {
    const interval = setInterval(() => {
      time = new Date()
    }, 1000)

    return () => {
      clearInterval(interval)
    }
  })
</script>

<style>
</style>

<main
  class="max-w-screen-sm py-5 px-4 mx-auto flex flex-col justify-items-center">
  <h1 class="text-orange-400 text-center text-5xl mb-4">Time Calculator</h1>
  <Button onClick={setCurrentTime}>Current time</Button>
  <div class="flex justify-between my-4">
    <Number bind:value={hour} name="Hour" />
    <Number bind:value={min} name="Min" />
    <Number bind:value={totalBreak} name="Break time" />
  </div>
  <p class="text-pink-500 text-2xl font-bold">
    Break over at {`${breakOverHour}:${breakOverMin}`}
    <br /> in {Math.round((breakTime.getTime() - time.getTime()) / 1000 / 60)} minutes
  </p>
</main>
