<script lang="ts">
  export let onClick
</script>

<button
  class="text-pink-500 bg-transparent border border-solid border-pink-500
    hover:bg-pink-500 hover:text-white active:bg-pink-600 font-bold uppercase
    w-full py-2 rounded outline-none focus:outline-none mr-1 mb-1"
  style="transition: all .15s ease"
  on:click={onClick}>
  <slot />
</button>
