<script lang="ts">
  import Icon from 'svelte-awesome'
  import { chevronUp, chevronDown } from 'svelte-awesome/icons'
  import Button from './Button.svelte'
  export let value = 0
  export let name

  function incrementNumber() {
    value++
  }
  function decrementNumber() {
    value--
  }
</script>

<style>
</style>

<div class="inline-flex items-center justify-items-center flex-col">
  <label class="text-pink-500 text-lg font-bold" for={name}>{name}</label>
  <Button onClick={incrementNumber}>
    <Icon data={chevronUp} />
  </Button>
  <input
    type="tel"
    {name}
    bind:value
    class="text-pink-500 bg-transparent border border-solid border-pink-500
      font-bold uppercase w-full py-2 rounded outline-none focus:outline-none
      mr-1 mb-1 text-center" />
  <Button onClick={decrementNumber}>
    <Icon data={chevronDown} />
  </Button>
</div>
